<template>
	<el-dialog top="1vh" title="司机详情" width="1050px" :visible.sync="is_show_in_page">
		<el-form class="form_ordinary" label-width="160px">
			<div class="big_tit">证件相关</div>
			<div class="tab1_inner">
				<div class="el_form_item" label="" style="width:48%">
					<div class="img_btn">
						<div v-if="!dl_img_src" class="btn">未上传</div>
					    <el-image
							v-else
							class="img"
							:src="dl_img_src"
							:previewSrcList="srcList"
							:z-index = 3000
							>
						</el-image>
						<!-- <img  v-else :src="dl_img_src" class="img" @click="show_img(dl_img_src)"> -->
					</div>
					<div class="bottom_text">行驶证
						<el-button class="btn_left" size="mini" type="text" v-if="dl_img_src" @click="img_save(dl_img_src,dl_img_name)">保存</el-button>
					</div>
				</div>
				<div class="el_form_item" style="width:48%;margin-left:1%">
					<div class="img_btn">
						<div v-if="!tl_img_src" class="btn">未上传</div>
						  <el-image
								v-else
								class="img"
								:src="tl_img_src"
								:previewSrcList="tlList"
								:z-index = 3000
								>
							</el-image>
						<!-- <img  v-else :src="cy_img_src" class="img" @click="show_img(cy_img_src)"> -->
					</div>
					<div class="bottom_text">道路运输证
						<el-button class="btn_left" size="mini" type="text" v-if="tl_img_src" @click="img_save(tl_img_src,tl_img_name)">保存</el-button>
					</div>
				</div>
			</div>

		</el-form>

	</el-dialog>
</template>
<script>

	export default {
		props:{
			is_show:Number,//是否显示
			truck_info:Object,//司机详情
		},
		data() {
			return {

				//页面是否显示
				is_show_in_page:false,

				//行驶证
				dl_img_src:'',
				srcList:[],
				//道路运输证
				tl_img_src:'',
				tlList:[],

				//下载图片名称
				dl_img_name:'',
				tl_img_name:''
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
				}
			},
			truck_info(new_data){
				if(new_data.id){
                  this.load_data(new_data)
				}
			}
		},
		methods: {
			//保存图片
			img_save(url,name){
				let image = new Image();
				image.setAttribute('crossOrigin', 'anonymous');
				image.src = url;
				image.onload = () => {
					let canvas = document.createElement('canvas');
					canvas.width = image.width;
					canvas.height = image.height;
					let ctx = canvas.getContext('2d');
					ctx.drawImage(image, 0, 0, image.width, image.height);
					canvas.toBlob((blob) => {
					let url = URL.createObjectURL(blob);
					this.download(url,name);
					// 用完释放URL对象
					URL.revokeObjectURL(url);
					});
				};
			},
			//发起下载
			download(href,name) {
				let eleLink = document.createElement('a');
				eleLink.download = name;
				eleLink.href = href;
				eleLink.click();
				eleLink.remove();
			},
			load_data(new_data){
                if(new_data.dl_img){
                    this.dl_img_src=this.$my.qiniu.make_src('truckdl',new_data.dl_img)
				    this.srcList[0]=this.dl_img_src
                }
				if(new_data.tl_img){
                    this.tl_img_src=this.$my.qiniu.make_src('truckdl',new_data.tl_img)
				    this.tlList[0]=this.tl_img_src
                }
				
				this.dl_img_name = new_data.truck_plate_num + '-'+'行驶证'
				this.tl_img_name = new_data.truck_plate_num + '-'+'道路运输证'
			},
		}
	}
</script>
<style lang="scss" scoped>

.el_form_item {
  margin: 0;
  .img_btn {
    border: 1px solid #bbb;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px;
    height: 300px;
    overflow: hidden;
    .btn {
      text-align: center;
      line-height: 290px;
      font-size: 14px;
    }

    .img {
      position: relative;
      display: block;
      height: 290px;
      /deep/.el-image__inner {
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: auto !important;
      }
    }
  }
  .bottom_text {
    margin-top: 5px;
    text-align: center;
  }
}
.big_tit {
  background-color: #eee;
  padding: 10px 20px;
  color: #666;
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
}
.tab1_inner {
  padding: 15px 15px 0 15px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.el_inner_width {
  width: 94%;
}

.bottom_btns {
  text-align: center;
  margin-top: 30px;
}

.form_ordinary {
  // height: calc(100% - 91px);
  padding: 10px 0;
  overflow-y: auto;
}
.upl {
  .img_area {
    border: 1px solid #ccc;
    cursor: pointer;
    padding: 5px;
    .show {
      margin: auto;
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
    .icon {
      text-align: center;
      display: block;
      font-size: 100px;
      height: 500px;
      line-height: 500px;
    }
  }
  .mark_area {
    border: 1px solid #ccc;
    padding: 5px;
    width: 448px;
    margin-top: 10px;
    height: 100px;
    outline: none;
    font-size: 16px;
  }
  .mark_area::placeholder {
    font-size: 20px;
    line-height: 100px;
    text-align: center;
  }
  .btn_area {
    text-align: center;
    margin-top: 15px;
  }
}

.voucher {
  margin-top: -10px;
  .header {
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }
  .list_none {
    height: 300px;
    line-height: 300px;
    text-align: center;
    font-size: 18px;
  }
}
.cy_num_text{
	font-size: 12px;
	color: red;
}
</style>