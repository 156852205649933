<template>
  <el-dialog
    top="1vh"
    title="货车详情"
    width="1050px"
    :visible.sync="is_show_in_page"
    @closed="turck_add_closed"
  >
    <el-form class="form_ordinary" label-width="160px">
        <div class="tab1_inner" style=" margin-bottom: 20px;">
            <el-form-item class="el_form_item" label="司机电话" style="width:45%">
                <el-input class="el_inner_width" v-model="form.tel" clearable>
                </el-input>
            </el-form-item>
            <el-form-item class="el_form_item" label="车牌号" style="width:45%">
                <el-input class="el_inner_width" v-model="form.truck_plate_num" clearable>
                    <el-button type="success" slot="append" @click="app_user_ser">查找</el-button>
                </el-input>
            </el-form-item>
        </div>
        <div v-if="input_lock">
            <div class="big_tit" style="margin-top: 0px">基本数据</div>
                <div class="tab1_inner">
                    <el-form-item class="el_form_item" label="所有人" style="width: 45%">
                    <el-input
                        class="el_inner_width"
                        v-model="form.truck_owner_name"
                        :disabled="input_lock!=true"
                    ></el-input>
                    </el-form-item>
                    <el-form-item class="el_form_item" label="车辆类型" style="width: 45%">
                    <el-input
                        class="el_inner_width"
                        v-model="form.truck_type1"
                        :disabled="input_lock!=true"
                    ></el-input>
                    </el-form-item>
                </div>
                <div class="tab1_inner">
                    <el-form-item class="el_form_item" label="使用性质" style="width: 45%">
                    <el-input class="el_inner_width" v-model="form.truck_used" :disabled="input_lock!=true"></el-input>
                    </el-form-item>
                    <el-form-item
                    class="el_form_item"
                    label="行驶证发证机关"
                    style="width: 45%"
                    >
                    <el-input class="el_inner_width" v-model="form.dl_ca" :disabled="input_lock!=true"></el-input>
                    </el-form-item>
                </div>
                <div class="tab1_inner">
                    <el-form-item
                    class="el_form_item"
                    label="行驶证注册日期"
                    style="width: 45%"
                    >
                    <el-date-picker
                        style="width: 265px"
                        :disabled="input_lock!=true"
                        v-model="time.dl_reg_date"
                        type="date"
                        placeholder="选择日期"
                        value-format="timestamp"
                    ></el-date-picker>
                    </el-form-item>
                    <el-form-item
                    class="el_form_item"
                    label="行驶证发证日期"
                    style="width: 45%"
                    >
                    <el-date-picker
                        style="width: 265px"
                        :disabled="input_lock!=true"
                        v-model="time.dl_award_date"
                        type="date"
                        placeholder="选择日期"
                        value-format="timestamp"
                    ></el-date-picker>
                    </el-form-item>
                </div>
                <div class="tab1_inner">
                    <el-form-item
                    class="el_form_item"
                    label="道路运输证号"
                    style="width: 45%"
                    >
                    <el-input class="el_inner_width" v-model="form.tl_code" :disabled="input_lock!=true"></el-input>
                    </el-form-item>
                    <el-form-item
                    class="el_form_item"
                    label="道路运输经营许可号"
                    style="width: 45%"
                    >
                    <el-input
                        class="el_inner_width"
                        v-model="form.tl_cert_code"
                        :disabled="input_lock!=true"
                    ></el-input>
                    </el-form-item>
                </div>
                <div class="tab1_inner">
                    <!-- <el-form-item class="el_form_item" label="车牌号" style="width: 45%">
                    <el-input
                        class="el_inner_width"
                        v-model="form.truck_plate_num"
                    ></el-input>
                    </el-form-item> -->
                    <el-form-item class="el_form_item" label="车牌类型" style="width: 45%">
                    <el-select class="el_inner_width" v-model="form.truck_plate_type" :disabled="input_lock!=true">
                        <el-option label="未选择" value="0"></el-option>
                        <el-option label="黄牌" value="1"></el-option>
                        <el-option label="蓝牌" value="2"></el-option>
                        <el-option label="绿牌" value="3"></el-option>
                    </el-select>
                    </el-form-item>
                    <el-form-item class="el_form_item" label="总质量" style="width: 45%">
                    <el-input
                        class="el_inner_width"
                        v-model="form.truck_weight_max"
                        :disabled="input_lock!=true"
                    ></el-input>
                    </el-form-item>
                </div>

                <div class="tab1_inner">
                    <el-form-item
                    class="el_form_item"
                    label="额定载重(吨)"
                    style="width: 45%"
                    >
                    <el-input
                        class="el_inner_width"
                        v-model="form.truck_weight"
                        :disabled="input_lock!=true"
                        clearable
                    ></el-input>
                    </el-form-item>
                    <el-form-item
                    class="el_form_item"
                    label="额载体积(方)"
                    style="width: 45%"
                    >
                    <el-input
                        class="el_inner_width"
                        v-model="form.truck_volume"
                        :disabled="input_lock!=true"
                        clearable
                    ></el-input>
                    </el-form-item>
                </div>
                <div class="tab1_inner">
                    <el-form-item class="el_form_item" label="能源类型" style="width: 45%" >
                    <el-select class="el_inner_width" v-model="form.en_type" :disabled="input_lock!=true">
                        <el-option label="汽油" value="A"></el-option>
                        <el-option label="柴油" value="B"></el-option>
                        <el-option label="电" value="C"></el-option>
                        <el-option label="混合油" value="D"></el-option>
                        <el-option label="天然气" value="E"></el-option>
                        <el-option label="液化石油气" value="F"></el-option>
                        <el-option label="甲醇" value="L"></el-option>
                        <el-option label="乙醇" value="M"></el-option>
                        <el-option label="太阳能" value="N"></el-option>
                        <el-option label="混合动力" value="O"></el-option>
                        <el-option label="无" value="Y"></el-option>
                        <el-option label="其他" value="Z"></el-option>
                    </el-select>
                    </el-form-item>
                    <el-form-item class="el_form_item" label="货车类型" style="width: 45%">
                    <el-cascader
                        class="el_inner_width"
                        v-model="form.truck_type"
                        :options="truck_type"
                        placeholder="必填"
                        filterable
                        :disabled="input_lock!=true"
                    ></el-cascader>
                    </el-form-item>
                </div>
                <div class="tab1_inner">
                    
                    <el-form-item
                    class="el_form_item"
                    label="车辆识别代号"
                    style="width: 45%"
                    >
                    <el-input
                        class="el_inner_width"
                        v-model="form.truck_vin_code"
                        :disabled="input_lock!=true"
                    ></el-input>
                    </el-form-item>
                </div>
                <div class="tab1_inner">
                
                </div>
                <div class="big_tit">证件相关</div>
                <div class="tab1_inner">
                    <div class="el_form_item" label="" style="width: 48%">
                    <div class="img_btn">
                        <div v-if="!dl_img_src" class="btn">未上传</div>
                        <el-image
                        v-else
                        class="img"
                        :src="dl_img_src"
                        :previewSrcList="srcList"
                        :z-index="3000"
                        >
                        </el-image>
                        <!-- <img  v-else :src="dl_img_src" class="img" @click="show_img(dl_img_src)"> -->
                    </div>
                    <div class="bottom_text">
                        行驶证
                        <el-button
                        class="btn_left"
                        size="mini"
                        type="text"
                        @click="voucher_add(1)"
                        v-if="input_lock"
                        >上传</el-button
                        >
                    </div>
                    </div>
                    <div class="el_form_item" style="width: 48%; margin-left: 1%">
                    <div class="img_btn">
                        <div v-if="!tl_img_src" class="btn">未上传</div>
                        <el-image
                        v-else
                        class="img"
                        :src="tl_img_src"
                        :previewSrcList="tlList"
                        :z-index="3000"
                        >
                        </el-image>
                        <!-- <img  v-else :src="tl_img_src" class="img" @click="show_img(tl_img_src)"> -->
                    </div>
                    <div class="bottom_text">
                        道路运输证
                        <el-button
                        class="btn_left"
                        size="mini"
                        type="text"
                        @click="voucher_add(2)"
                        v-if="input_lock"
                        >上传</el-button
                        >
                    </div>
                    </div>
                </div>
        </div>
    
    </el-form>

    <div class="bottom_btns" v-if="status!=2&&input_lock">
      <el-button type="success" @click="pass()">提交</el-button>
    </div>

    <el-dialog
      top="2vh"
      width="90%"
      title="查看大图"
      :modal="false"
      :visible.sync="img.is_show"
    >
      <img :src="img.src" style="width: 100%" />
    </el-dialog>
    <!-- 凭证添加界面 -->
    <el-dialog
      title="证件上传"
      top="2vh"
      width="500px"
      :visible.sync="upl.is_show"
      :modal="false"
    >
      <div class="upl">
        <div class="img_area" @click="voucher_add_sub">
          <img v-if="upl.src" :src="upl.src" class="show" />
          <i v-else class="el-icon-plus avatar-uploader-icon icon"></i>
        </div>

        <div class="btn_area">
          <el-button type="primary" @click="voucher_sub(num)">上传</el-button>
        </div>
      </div>
    </el-dialog>
  </el-dialog>
</template>
<script>
import truck_type_handle from "@/my/_truck_type_handle.js";
export default {
  props: {
    is_show: Number, //是否显示
    truck_info: Object, //货车详情
  },
  data() {
    return {
      input_lock:false,
      status:'',//车辆审核是否通过
      //页面是否显示
      is_show_in_page: false,

      //货车编号
      truck_inner_num: "",

      //表单
      form: {
        truck_plate_num: "", //车牌号
        truck_plate_type: "", //车牌照类型(0:未知,1:黄牌,2:蓝牌,3:绿牌)
        truck_weight: "", //货车额定载重(单位:吨)
        truck_volume: "", //货车额载体积(单位:方)
        en_type: "", //能源类型(A:汽油,B:柴油,C:电,D:混合油,E:天然气,F:液化石油气,L:甲醇,M:乙醇,N:太阳能,O:混合动力,Y:无,Z:其他)
        truck_type: null, //货车类型
        truck_source: "", //车辆来源
        // check_faild_reason: "", //审核失败原因
        tel: "", //司机号码
        truck_vin_code: "", // 车辆识别代号
        truck_used: "", // 使用性质
        dl_ca: "", //行驶证发证机关
        dl_reg_date: "", //行驶证注册日期
        dl_award_date: "", // 行驶证发证日期
        tl_code: "", //道路运输证号
        dl_img: "",
        tl_img: "",
        tl_cert_code: "", //道路运输证经营许可证号
        truck_weight_max: "", //车辆总重(单位:吨)
        truck_owner_name: "", //所有人姓名
        truck_type1:'',//车辆类型
      },
      //日期
      time: {
        dl_reg_date: "",
        dl_award_date: "",
      },
      //行驶证
      dl_img_src: "",
      srcList: [],
      //道路运输证
      tl_img_src: "",
      tlList: [],
      //车辆来源证明
      truck_source_proof_src: "",

      //大图
      img: {
        is_show: false,
        src: "",
      },
      //上传界面
      upl: {
        //是否显示上传界面
        is_show: false,

        //地址
        src: "",

        //备注
        mark: "",
      },
      //车型
      truck_type: [],

      truck_add_lock:true,//提交锁
    };
  },
  watch: {
    is_show(new_data) {
      if (new_data) {
        this.is_show_in_page = true;
        this.img.is_show = false;
      }
    },
    truck_info(new_data) {
      if (new_data) {
        this.load_data(new_data);
      }
    },
  },
  created() {
    //获取参数
    this.get_creat_para();
  },

  methods: {
    app_user_ser(){
        if(!this.form.tel){
            this.$my.other.msg({
                type: "warning",
                str: "请输入手机号",
            });
            return;
        }
        if(!this.form.truck_plate_num){
            this.$my.other.msg({
                type: "warning",
                str: "请输入车牌号",
            });
            return;
        }
        this.input_lock = true
        this.$my.net.req({
            data:{
                mod:'app_user',
                ctr:'get_user_all_api_by_shipper',
                tel:this.form.tel,
                truck_plate_num:this.form.truck_plate_num
            },callback:(data)=>{
                if(!data.truck_info){
                    this.$my.other.msg({
                        type: "warning",
                        str: "查询为空",
                    });
                    //清空数据
                    this.real_status='',//用户审核是否通过
                    this.status='',//司机审核是否通过
                    this.form = {
                        truck_plate_type: "", //车牌照类型(0:未知,1:黄牌,2:蓝牌,3:绿牌)
                        truck_weight: "", //货车额定载重(单位:吨)
                        truck_volume: "", //货车额载体积(单位:方)
                        en_type: "", //能源类型(A:汽油,B:柴油,C:电,D:混合油,E:天然气,F:液化石油气,L:甲醇,M:乙醇,N:太阳能,O:混合动力,Y:无,Z:其他)
                        truck_type: null, //货车类型
                        truck_source: "", //车辆来源
                        truck_vin_code: "", // 车辆识别代号
                        truck_used: "", // 使用性质
                        dl_ca: "", //行驶证发证机关
                        dl_reg_date: "", //行驶证注册日期
                        dl_award_date: "", // 行驶证发证日期
                        tl_code: "", //道路运输证号
                        tel:this.form.tel,
                        truck_plate_num:this.form.truck_plate_num,
                        dl_img: "",
                        tl_img: "",
                        tl_cert_code: "", //道路运输证经营许可证号
                        truck_weight_max: "", //车辆总重(单位:吨)
                        truck_owner_name: "", //所有人姓名
                    };
                    //日期
                    this.time={
                        dl_reg_date: "",
                        dl_award_date: "",
                    },
                    this.tl_img_src = "";
                    this.dl_img_src = "";
                    return;
                }
                this.status = data.truck_info.status
                let new_data = data.truck_info
                // 置入数据
                this.truck_inner_num=new_data.truck_inner_num;
                this.truck_id = new_data.id;
                this.form.truck_plate_num = new_data.truck_plate_num;
                this.form.truck_plate_type = new_data.truck_plate_type;
                this.form.truck_weight = new_data.truck_weight;
                this.form.truck_volume = new_data.truck_volume;
                this.form.en_type = new_data.en_type;
                this.form.truck_vin_code =new_data.truck_vin_code, // 车辆识别代号
                this.form.truck_used =new_data.truck_used // 使用性质
                this.form.dl_ca =new_data.dl_ca //行驶证发证机关
                this.form.truck_type1 =new_data.truck_type //道路运输证号
                this.form.truck_weight_max =new_data.truck_weight_max //车辆总重(单位:吨)
                this.form.truck_owner_name =new_data.truck_owner_name //所有人姓名
                this.form.manual_ver = new_data.manual_ver // 五证是否齐全
                if(new_data.manual_ver ==2){
                  this.manual_ver_checked = true
                }else{
                  this.manual_ver_checked = false
                }
                this.form.truck_type = [
                  new_data.truck_type_name,
                  new_data.truck_type_name2,
                ];
                this.form.truck_source = new_data.truck_source;
                this.form.check_faild_reason = new_data.check_faild_reason;
                //日期处理
                if(new_data.dl_reg_date !=0){
                  this.time.dl_reg_date =Number(new_data.dl_reg_date) *1000
                }
                if(new_data.dl_award_date !=0){
                  this.time.dl_award_date =Number(new_data.dl_award_date) *1000
                }
                this.form.dl_img = new_data.dl_img
                //图片处理
                if(new_data.dl_img.substr(0,4)=='http'){
                    this.dl_img_src=new_data.dl_img
                    this.srcList[0]=new_data.dl_img
                }else{
                   this.dl_img_src=this.$my.qiniu.make_src('truckdl',new_data.dl_img)
                   this.srcList[0]=this.$my.qiniu.make_src('truckdl',new_data.dl_img)
                }
                 this.form.tl_img = new_data.tl_img
                if(new_data.tl_img.substr(0,4)=='http'){
                    this.tl_img_src=new_data.tl_img
                    this.tlList[0]=new_data.tl_img
                }else{
                   this.tl_img_src=this.$my.qiniu.make_src('truckdl',new_data.tl_img)
                   this.tlList[0]=this.$my.qiniu.make_src('truckdl',new_data.tl_img)
                }
                this.form.tl_code =new_data.tl_code //道路运输证号
                this.form.tl_cert_code = new_data.tl_cert_code

            }
        })
    },
    voucher_add(num) {
      this.num = num;
      //打开添加凭证界面

      //打开上传界面
      this.upl.is_show = true;

      //清空上次上传的图片
      this.upl.src = "";

      //清空备注
      this.upl.mark = "";
    },
    voucher_sub(num) {
      //上传
      this.$my.qiniu.upl({
        bucket: "driversourceproof",
        file_path: this.upl.src,
        callback: (data) => {
          var key = data.key.split("__v__1");

          // 判断
          if (num == 1) {
            this.form.dl_img = key[0];
            this.dl_img_src = this.$my.qiniu.make_src("truckdl", data.key);
            this.srcList[0] = this.$my.qiniu.make_src("truckdl", data.key);
            //调用接口
            this.$my.net.req({
              data: {
                mod: "res",
                ctr: "img_ocr",
                img_type: "driving_license",
                img_url: this.$my.qiniu.make_src("truckdl", data.key),
              },
              callback: (res) => {
                this.form.truck_plate_num=res.truck_plate_num
                this.form.truck_vin_code = res.truck_vin_code
                this.form.truck_owner_name = res.truck_owner_name
                this.form.truck_plate_num = res.truck_plate_num
                this.time.dl_award_date = new Date(res.dl_award_date).getTime()
                this.time.dl_reg_date =new Date(res.dl_reg_date).getTime()
                this.form.truck_used = res.truck_used
                this.form.dl_ca = res.truck_seal
                this.form.truck_type1 = res.truck_type
                this.form.dl_ocr_rel=res
              },
            });
          } else {
            this.tl_img_src = this.$my.qiniu.make_src("truckdl", data.key);
            this.tlList[0] = this.$my.qiniu.make_src("trucktl", data.key);
            this.form.tl_img = key[0];
            //道路运输证识别
            this.$my.net.req({
                data:{
                    mod:'res',
                    ctr:'ocr_normal_img',
                    car_img:this.$my.qiniu.make_src('truckdl',data.key)
                },callback:(data)=>{
                    this.form.tl_code = data.tl_code
                    //识别不出来默认四个0
                    if(data.jy_code){
                        this.form.tl_cert_code = data.jy_code
                    }else{
                        this.form.tl_cert_code ='0000'
                    }
                    this.tl_img_src=this.$my.qiniu.make_src('truckdl',this.form.tl_img)
                    this.tlList[0]=this.tl_img_src
                }
            })
          }
          this.upl.is_show = false;
        },
      });
    },
    voucher_add_sub() {
      //提交凭证

      //选择图片
      this.$my.qiniu.choose_img({
        success: (res) => {
          //预览
          this.upl.src = res.src;
        },
      });
    },
    //通过
    pass() {
      if(!this.truck_add_lock){
        return
      }
      this.truck_add_lock = false 
      //取出数据
      let new_data = this.form;
      let start_time = Number(this.time.dl_reg_date) / 1000;
      let end_time = Number(this.time.dl_award_date) / 1000;
      //车牌号
      // if (!this.$my.check.is_plate_num(new_data.truck_plate_num)) {
      //   this.$my.other.msg({
      //     type: "warning",
      //     str: "缺少车牌号",
      //   });
      //   return;
      // }

      //车牌类型
      if (["1", "2", "3"].indexOf(new_data.truck_plate_type) == -1) {
        this.$my.other.msg({
          type: "warning",
          str: "缺少车牌类型",
        });
         this.truck_add_lock = true
        return;
      }

      //额定载重
      if (!this.$my.check.is_num(new_data.truck_weight)) {
        this.$my.other.msg({
          type: "warning",
          str: "缺少额定载重",
        });
         this.truck_add_lock = true
        return;
      }
      if (new_data.truck_weight <= 0) {
        this.$my.other.msg({
          type: "warning",
          str: "缺少额定载重",
        });
         this.truck_add_lock = true
        return;
      }

      //货车类型
      if (!this.$my.check.is_arr(new_data.truck_type)) {
        this.$my.other.msg({
          type: "warning",
          str: "缺少货车类型",
        });
         this.truck_add_lock = true
        return;
      }
      if (new_data.truck_type.length != 2) {
        this.$my.other.msg({
          type: "warning",
          str: "缺少货车类型",
        });
        this.truck_add_lock = true
        return;
      }
      //调接口
      this.$my.net.req({
        take_over_control:1,
        data: {
          mod: "app_user",
          ctr: "set_user_all_api_by_shipper",
          truck_inner_num: this.truck_inner_num,
          truck_plate_num: new_data.truck_plate_num,
          truck_plate_type: new_data.truck_plate_type,
          truck_weight: new_data.truck_weight,
          truck_volume: new_data.truck_volume,
          tel: new_data.tel,
          truck_img: new_data.dl_img,
          tl_img: new_data.tl_img,
          en_type: new_data.en_type,
          truck_type:new_data.truck_type1,
          truck_type_name: new_data.truck_type[0],
          truck_type_name2: new_data.truck_type[1],
          dl_ocr_rel: new_data.dl_ocr_rel,
          truck_vin_code: new_data.truck_vin_code,
          truck_used: new_data.truck_used,
          dl_reg_date: start_time,
          dl_award_date: end_time,
          tl_code: new_data.tl_code,
          tl_cert_code: new_data.tl_cert_code,
          truck_seal: new_data.dl_ca,
          truck_weight_max: new_data.truck_weight_max,
          truck_owner_name: new_data.truck_owner_name,
        },
        callback: (data) => {
          if(data.code ==0){
            this.$my.other.msg({
              type: "success",
              str: "提交成功",
            });
            this.is_show_in_page = false;
          }
          this.truck_add_lock = true
          this.$emit("close");
        },
      });
    },
    //弹窗关闭 清空数据
    turck_add_closed() {
      this. input_lock=false,
      this.real_status='',//用户审核是否通过
      this.status='',//司机审核是否通过
      this.truck_inner_num=''
      this.form = {
        truck_plate_num: "", //车牌号
        truck_plate_type: "", //车牌照类型(0:未知,1:黄牌,2:蓝牌,3:绿牌)
        truck_weight: "", //货车额定载重(单位:吨)
        truck_volume: "", //货车额载体积(单位:方)
        en_type: "", //能源类型(A:汽油,B:柴油,C:电,D:混合油,E:天然气,F:液化石油气,L:甲醇,M:乙醇,N:太阳能,O:混合动力,Y:无,Z:其他)
        truck_type: null, //货车类型
        truck_source: "", //车辆来源
        // check_faild_reason: "", //审核失败原因
        tel: "", //司机号码
        truck_vin_code: "", // 车辆识别代号
        truck_used: "", // 使用性质
        dl_ca: "", //行驶证发证机关
        dl_reg_date: "", //行驶证注册日期
        dl_award_date: "", // 行驶证发证日期
        tl_code: "", //道路运输证号
        dl_img: "",
        tl_img: "",
        tl_cert_code: "", //道路运输证经营许可证号
        truck_weight_max: "", //车辆总重(单位:吨)
        truck_owner_name: "", //所有人姓名
      };
      //日期
      this.time={
        dl_reg_date: "",
        dl_award_date: "",
      },
      this.tl_img_src = "";
      this.dl_img_src = "";
    },
    //获取创建参数
    get_creat_para() {
      //尝试从缓存中取出
      let creat_para = this.$my.cache.get("cargo_creat_page_creat_para");

      //如果取出成功
      if (creat_para) {
        this.truck_type = creat_para.truck_type;
        return;
      }

      //如果取出失败,去服务器读取
      this.$my.net.req({
        data: {
          mod: "truck_tord_real_time",
          ctr: "cargo_creat_para",
        },
        callback: (data) => {
          //格式化车型数据
          let truck_type = truck_type_handle.for_el_cascader(data.truck_type);

          //缓存之
          this.$my.cache.set({
            cargo_creat_page_creat_para: {
              truck_type: truck_type,
              cargo_type: data.cargo_type,
            },
          });

          //置入
          this.truck_type = truck_type;
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el_form_item {
  margin: 0;
  .img_btn {
    border: 1px solid #bbb;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px;
    height: 300px;
    .btn {
      text-align: center;
      line-height: 290px;
      font-size: 14px;
    }

    .img {
      position: relative;
      display: block;
      height: 290px;
      /deep/.el-image__inner {
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: auto !important;
      }
    }
  }
  .bottom_text {
    margin-top: 5px;
    text-align: center;
  }
}
.big_tit {
  background-color: #eee;
  padding: 10px 20px;
  color: #666;
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
}
.tab1_inner {
  padding: 15px 15px 0 15px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.el_inner_width {
  width: 94%;
}

.bottom_btns {
  text-align: center;
  margin-top: 30px;
}

.form_ordinary {
  // height: calc(100% - 91px);
  padding: 10px 0;
  overflow-y: auto;
}
.upl {
  .img_area {
    border: 1px solid #ccc;
    cursor: pointer;
    padding: 5px;
    .show {
      margin: auto;
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
    .icon {
      text-align: center;
      display: block;
      font-size: 100px;
      height: 500px;
      line-height: 500px;
     
    }
  }
  .mark_area {
    border: 1px solid #ccc;
    padding: 5px;
    width: 448px;
    margin-top: 10px;
    height: 100px;
    outline: none;
    font-size: 16px;
  }
  .mark_area::placeholder {
    font-size: 20px;
    line-height: 100px;
    text-align: center;
  }
  .btn_area {
    text-align: center;
    margin-top: 15px;
  }
}
</style>